@import "../../../shared/scss/Common-Style.scss";

.manage-component {
  .sub-head {
    border-bottom: 1px solid #000;

    p {
      background: #191717 0% 0% no-repeat padding-box;
      border: 1px solid #ccc5c5;
      width: 260px;
      color: #fff;
      padding: 10px 20px;
      margin-bottom: -1px;
      text-align: center;
      border-bottom: 0;
    }
  }

  .manage-body {
    min-height: 300px;
    margin: 20px 0 10px 20px;
    position: relative;

    @media (min-width: $breakpoint-tablet) {
      margin: 20px 0 10px 35px;
    }

    .reference-guide,
    .info-text {
      font-size: 12px;
      line-height: 14px;
      margin: 10px 0;
      padding-right: 20px;

      a {
        color: inherit;

        &:hover {
          color: #e10a1d;
        }
      }
    }

    .status-codes {
      font-size: 12px;
      line-height: 18px;
      padding-left: 15px;

      @media (min-width: $breakpoint-tablet) {
        position: absolute;
        top: 0;
        right: 25px;
      }

      ul {
        padding-left: 0;

        li {
          list-style: none;
          position: relative;

          .status-roundend {
            height: 6px;
            width: 6px;
            display: inline-block;
            position: static;
            border-radius: 50%;
            margin: 2px 5px;
          }
        }
      }
    }

    .categories-list {
      position: relative;
      display: block;
      max-width: max-content;

      div {
        &.heading {
          max-width: max-content;
          cursor: pointer;
          margin: 0 0 5px 0;

          .heading-text {
            color: #1d1d1d;
            font-weight: bold;

            .status-roundend {
              height: 8px;
              width: 8px;
              display: inline-block;
              position: static;
              border-radius: 50%;
              margin-right: 3px;
            }
          }

          button {
            &.plusmenu-danger {
              cursor: pointer;
              border: none;
              border-radius: 50%;
              background: transparent;

              i {
                position: relative;

                &.minus-checked {
                  &::after {
                    content: "";
                    background: url("../../../assets/Group 162.svg");
                    width: 20px;
                    height: 22px;
                    position: absolute;
                    top: -18px;
                    left: -15px;
                    background-repeat: no-repeat;
                  }
                }

                &.plus-checked {
                  &::after {
                    content: "";
                    background: url("../../../assets/Group 410.svg");
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: -18px;
                    left: -15px;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
          }

          &:hover {
            .dropdown-content {
              display: inline-block;
              transition: 0.1s;
            }
          }

          .dropdown-content {
            display: none;
            position: absolute;
            width: max-content;
            z-index: 100;

            .selectble-popup {
              padding: 0;
              background-color: #fff;
              box-sizing: border-box;
              border: 1px solid #707070;

              li {
                padding: 0.2em 1.25em;
                list-style: none;
                font-family: $toyota-book;
                font-size: 12px;
                justify-content: start;
                align-items: center;
                text-align: left;
                border-bottom: 1px solid #707070;

                &:before {
                  content: none;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  background-color: #000;
                  color: #fff;
                  width: auto;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .sub-category {
        position: relative;
        display: block;

        &.btn-nosub {
          ul {
            padding-left: 44px;

            li {
              &:before {
                width: 30px;
                left: -25px;
                top: 10px;
              }
            }
          }
        }

        ul {
          padding-left: 30px;
          position: relative;
          margin-bottom: 0;
          padding-top: 4px;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            display: block;
            background: #000;
            left: 12px;
            top: 5px;
          }

          li {
            list-style: none;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 2px;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              height: 1.4px;
              width: 13px;
              display: inline-block;
              background: #000;
              left: -11px;
              top: 14px;
            }

            .status-roundend {
              height: 8px;
              width: 8px;
              display: inline-block;
              position: static;
              border-radius: 50%;
              margin-right: 3px;
            }

            button {
              &.plusmenu-danger {
                cursor: pointer;
                border: none;
                border-radius: 50%;
                background: transparent;

                i {
                  position: relative;

                  &.minus-checked {
                    background: transparent;

                    &::after {
                      content: "";
                      background: url("../../../assets/Group 162.svg");
                      width: 20px;
                      height: 22px;
                      position: absolute;
                      top: -18px;
                      left: -15px;
                      background-repeat: no-repeat;
                    }
                  }

                  &.plus-checked {
                    &::after {
                      content: "";
                      background: url("../../../assets/Group 410.svg");
                      width: 30px;
                      height: 30px;
                      position: absolute;
                      top: -18px;
                      left: -15px;
                      background-repeat: no-repeat;
                    }
                  }
                }
              }
            }

            &:hover {
              .dropdown-content {
                display: inline-block;
              }
            }

            .dropdown-content {
              display: none;
              position: absolute;
              width: max-content;
              z-index: 100;

              .selectble-popup {
                padding: 0;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #707070;

                &:before {
                  content: none;
                }


                li {
                  padding: 0.2em 1.25em;
                  list-style: none;
                  font-family: $toyota-book;
                  font-size: 12px;
                  justify-content: start;
                  align-items: center;
                  text-align: left;
                  border-bottom: 1px solid #707070;

                  &:before {
                    content: none;
                  }

                  &:last-child {
                    border-bottom: none;
                  }

                  &:hover {
                    background-color: #000;
                    color: #fff;
                    width: auto;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          p {
            &.category-item {
              padding-left: 50px;
            }
          }
        }

        .sub-category {
          &.btn-nosub {
            ul {
              padding-left: 42px;

              li {
                &:before {
                  width: 30px;
                  left: -25px;
                  top: 10px;
                }
              }
            }
          }

          ul {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              display: block;
              background: #000;
              left: 12px;
              top: 5px;
            }

            li {
              font-size: 12px;
              line-height: 18px;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                height: 1.4px;
                width: 13px;
                display: inline-block;
                background: #000;
                left: -11px;
                top: 14px;
              }
            }
          }
        }

        .final-category {
          ul {
            padding-left: 38px;
            margin-bottom: 0;

            li {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }

    .btn {
      width: 200px;
      height: 35px;
      font-size: 16px;
      line-height: 16px;

      &:focus {
        box-shadow: none;
      }

      &.btn-primary {
        background: #fff;
        color: #000;
        border: 1px solid #707070;
        border-radius: 1px;
        margin-right: 20px;
      }
    }
  }

  .button-options {
    text-align: right;
    margin: 15px 20px 35px 0;

    @media (min-width: $breakpoint-tablet) {
      margin: 15px 60px 35px 0;
    }

    .btn {
      height: 55px;
      font-size: 16px;
      line-height: 16px;
      padding: 0 20px;

      &:focus {
        box-shadow: none;
      }

      &.btn-primary {
        background: #fff;
        color: #000;
        border: 1px solid #707070;
        border-radius: 1px;
        margin-right: 20px;
      }

      &.btn-secondary {
        color: #fff;
        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 1px;
      }
    }
  }
}

// Submit for Approval Pop Up

.approval-popup {
  .modal-dialog {
    max-width: 450px;
    position: absolute;
    top: 20%;

    @media (min-width: $breakpoint-tablet) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
    }

    .modal-content {
      padding-bottom: 0;
    }

    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      padding: 1.5rem;
      text-align: center;

      @media (min-width: $breakpoint-tablet) {
        padding: 1.5rem 2.5rem;
      }

      p {
        font-family: $toyota-book;
        font-size: 20px;
        line-height: 36px;
        color: #000;
      }

      .button-options {
        justify-content: center;

        .btn {
          &.btn-primary {
            margin: 10px 0;
            width: 200px;
          }
        }
      }
    }
  }
}