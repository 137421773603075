.nav {
  .emptyNav{
    height: 35px;
    background-color: #cccccc;
    margin-bottom: 5px;
  }
  .emptySecondNav{
    background-color: #f6f6f6;
    height: 580px;
  }
  .nav-item {
    &:first-child {
      a {
        &::before {
          background-image: url("../../../assets/dashboard-default.svg");
          width: 15px;
          height: 15px;
          left: 10px;
        }

        &.active {
          &::before {
            background-image: url("../../../assets/dashboard-select.svg");
          }
        }
      }
    }

    a {
      letter-spacing: -0.01px;
      color: #000000;
      font-size: 14px;
      line-height: 16px;
      background: #cccccc 0% 0% no-repeat padding-box;
      padding: 15px 5px 15px 35px;
      margin-bottom: 5px;
      position: relative;
      text-decoration: none;

      &::before {
        content: "";
        background-image: url("../../../assets/inactive-icon.PNG");
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: inherit;
      }

      &.active {
        background: #000000 0% 0% no-repeat padding-box;
        color: #fff;
        text-decoration: none;

        &::before {
          background-image: url("../../../assets/Icon-material-select-all.svg");
        }
      }

      &.right-arrow {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          border-left: 5px solid #000;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          margin-left: 5px;
          top: 20px;
          right: 15px;
        }

        &.open {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
    }


  }

  .sub-dropdown {
    display: block;

    .nav-item {
      a {
        &::before {
          content: none;
          background: none;
        }
      }
    }
  }
}