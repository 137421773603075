@import "../../../shared/scss/Common-Style.scss";

.modal-newcategory {
  .modal-dialog {
    .modal-body {
      .subhead {
        p {
          display: inline-block;
        }
      }
    }
  }
}

.create-levels p.plusmenu-danger {
  text-align: right;
  margin-right: 1rem;
  cursor: pointer;
}
